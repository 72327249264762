import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PostInList from "../components/PostInList"

// Components
import { graphql } from "gatsby"

const Tags = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  const image = posts[0].node.frontmatter.images[0].childImageSharp.original.src
  return (
    <Layout>
      <SEO image={image} />
      <div className="blog-posts">
        {posts.map(({ node: post }) => {
          return <PostInList post={post} />
        })}
      </div>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          ...PostNode
        }
      }
    }
  }
`
